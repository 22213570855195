body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #23272a;
  color: #ffffff;
  font-family: 'Rubik';
  font-size: 16px;
  scroll-behavior: smooth;
}

input,
button,
select,
option,
textarea {
  font-family: 'Rubik';
  color: black;
  padding: 7px;
  font-size: 16px;
  border-radius: 10px;
  border: 0px;
  outline: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 7px;
  color: gold;
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 700;
}

h3 {
  font-weight: 500;
}

button {
  background-color: gold;
  border: 1px solid goldenrod;
  font-size: 18px;
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    background-color: goldenrod;
  }
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
