#search_modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #2c2f33;
  z-index: 3;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  .search-input {
    position: fixed;
    top: 35px;
    width: calc(100% - 60px);
  }
  .close-button {
    position: absolute;
    top: 0px;
    left: 10px;
  }
  .results {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 50px;
    border-top: 1px solid #35383d;
    .result {
      padding: 10px 20px;
      display: grid;
      grid-template-columns: 50px 2fr 30px;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid #35383d;
      color: #99aab5;
      &:active {
        background-color: #35383d;
      }
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        background: radial-gradient(white, rgb(255, 239, 149));
        padding: 5px;
        border-radius: 10px;
      }
    }
  }
}
